import React from "react";
// import iconCSS from './icon.module.css'

export default function Github(props) {
  return (
    <a href={props.github}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 46 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6824 0.96521C10.1504 0.96521 0 11.1156 0 23.6476C0 33.6845 6.49282 42.162 15.5091 45.1674C16.6432 45.3659 17.0685 44.6854 17.0685 44.09C17.0685 43.5513 17.0401 41.7651 17.0401 39.8654C11.3412 40.9145 9.86682 38.4762 9.41318 37.2003C9.158 36.5481 8.05223 34.5351 7.08823 33.9964C6.29435 33.5711 5.16023 32.522 7.05988 32.4937C8.84612 32.4653 10.122 34.1381 10.5473 34.8186C12.5887 38.2493 15.8493 37.2853 17.1535 36.6899C17.352 35.2156 17.9474 34.2232 18.5995 33.6562C13.5527 33.0891 8.27906 31.1327 8.27906 22.4567C8.27906 19.99 9.158 17.9486 10.604 16.3609C10.3772 15.7938 9.58329 13.4689 10.8308 10.35C10.8308 10.35 12.7305 9.75462 17.0685 12.675C18.8831 12.1646 20.8111 11.9094 22.7391 11.9094C24.6671 11.9094 26.5951 12.1646 28.4096 12.675C32.7476 9.72627 34.6473 10.35 34.6473 10.35C35.8948 13.4689 35.1009 15.7938 34.8741 16.3609C36.3201 17.9486 37.1991 19.9617 37.1991 22.4567C37.1991 31.1611 31.8971 33.0891 26.8502 33.6562C27.6725 34.365 28.3813 35.7259 28.3813 37.8524C28.3813 40.8862 28.3529 43.3245 28.3529 44.09C28.3529 44.6854 28.7782 45.3943 29.9124 45.1674C34.4154 43.6477 38.3285 40.7539 41.1005 36.8934C43.8725 33.0329 45.3639 28.4002 45.3647 23.6476C45.3647 11.1156 35.2144 0.96521 22.6824 0.96521Z"
          fill="#8E8E8E"
        />
      </svg>
    </a>
  );
}
