import React from "react";

function PhoneIcon(props) {
  return (
    <a href={props.tele}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.7811 13.8819C11.1824 13.8819 9.62417 13.5255 8.10628 12.8126C6.5879 12.1003 5.24327 11.1584 4.07239 9.98705C2.90102 8.81617 1.95916 7.47154 1.24681 5.95316C0.533971 4.43528 0.177551 2.877 0.177551 1.27831C0.177551 1.05524 0.251909 0.869346 0.400623 0.720631C0.549338 0.571916 0.735232 0.497559 0.958304 0.497559H3.96978C4.14328 0.497559 4.29819 0.553327 4.43451 0.664863C4.57084 0.776399 4.65139 0.918917 4.67618 1.09242L5.1595 3.69493C5.18428 3.86843 5.18131 4.02631 5.15058 4.16858C5.11934 4.31135 5.04796 4.4385 4.93643 4.55004L3.15185 6.37179C3.67235 7.26408 4.32298 8.1006 5.10373 8.88136C5.88448 9.66211 6.74579 10.3375 7.68765 10.9076L9.43505 9.1602C9.54659 9.04866 9.69233 8.96489 9.87227 8.90887C10.0517 8.85335 10.2282 8.83798 10.4017 8.86277L12.967 9.38327C13.1405 9.42045 13.283 9.50398 13.3946 9.63385C13.5061 9.76423 13.5619 9.91616 13.5619 10.0897V13.1011C13.5619 13.3242 13.4875 13.5101 13.3388 13.6588C13.1901 13.8075 13.0042 13.8819 12.7811 13.8819Z"
          fill="#8E8E8E"
        />
      </svg>
    </a>
  );
}

export default PhoneIcon;
