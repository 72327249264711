import React from "react";

export default function Linkedin(props) {
  return (
    <a href={props.linkedin}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 46 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.3181 0.96521C10.7908 0.96521 0.635742 11.1203 0.635742 23.6476C0.635742 36.1748 10.7908 46.3299 23.3181 46.3299C35.8454 46.3299 46.0004 36.1748 46.0004 23.6476C46.0004 11.1203 35.8454 0.96521 23.3181 0.96521ZM17.7656 33.0489H13.1725V18.2676H17.7656V33.0489ZM15.4407 16.453C13.99 16.453 13.052 15.4252 13.052 14.1541C13.052 12.8569 14.0183 11.8598 15.4998 11.8598C16.9812 11.8598 17.8885 12.8569 17.9169 14.1541C17.9169 15.4252 16.9812 16.453 15.4407 16.453ZM34.5411 33.0489H29.948V24.8573C29.948 22.9506 29.2817 21.6558 27.6207 21.6558C26.3519 21.6558 25.5981 22.5323 25.265 23.3758C25.1421 23.6759 25.1114 24.1012 25.1114 24.5241V33.0466H20.5159V22.9813C20.5159 21.136 20.4568 19.5931 20.3954 18.2652H24.3861L24.5963 20.3185H24.6885C25.2933 19.3545 26.7748 17.9321 29.2533 17.9321C32.2753 17.9321 34.5411 19.957 34.5411 24.3091V33.0489Z"
          fill="#8E8E8E"
        />
      </svg>
    </a>
  );
}
