import React from "react";

function YouTubeIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: "scale(1.4)", marginLeft: "4px" }}
    >
      <g clipPath="url(#clip0_985_608)">
        <path
          d="M45.041 14.4534C45.041 14.4534 44.6046 11.1317 43.2604 9.67312C41.5583 7.75349 39.6555 7.74408 38.7826 7.63116C32.5329 7.14185 23.1497 7.14185 23.1497 7.14185H23.1322C23.1322 7.14185 13.7489 7.14185 7.49924 7.63116C6.62638 7.74408 4.72353 7.75349 3.02145 9.67312C1.67725 11.1317 1.24954 14.4534 1.24954 14.4534C1.24954 14.4534 0.795654 18.3585 0.795654 22.2542V25.9053C0.795654 29.801 1.24081 33.7061 1.24081 33.7061C1.24081 33.7061 1.67725 37.0279 3.01272 38.4864C4.71481 40.406 6.94933 40.3402 7.9444 40.5472C11.5231 40.9142 23.1409 41.0271 23.1409 41.0271C23.1409 41.0271 32.5329 41.0083 38.7826 40.5284C39.6555 40.4154 41.5583 40.406 43.2604 38.4864C44.6046 37.0279 45.041 33.7061 45.041 33.7061C45.041 33.7061 45.4862 29.8104 45.4862 25.9053V22.2542C45.4862 18.3585 45.041 14.4534 45.041 14.4534ZM18.5235 30.3374V16.7965L30.5952 23.5904L18.5235 30.3374Z"
          fill="white"
        />
      </g>
      <linearGradient
        id="paint0_linear_737_546"
        x1="0.985352"
        y1="0.546875"
        x2="39.5754"
        y2="3.75022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D19A08" />
        <stop offset="0.276042" stopColor="#FEDB7E" />
        <stop offset="0.5" stopColor="#EAC460" />
        <stop offset="0.661458" stopColor="#D19A08" />
        <stop offset="1" stopColor="#D19A08" />
      </linearGradient>
      <defs></defs>
    </svg>
  );
}

export default YouTubeIcon;
