import React from "react";

function MaleAvatar() {
  return (
    <svg
      width="166"
      height="166"
      viewBox="0 0 150 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1375_1330)">
        <mask
          id="mask0_1375_1330"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="150"
          height="150"
        >
          <circle
            cx="75.0731"
            cy="75.1273"
            r="74.1949"
            fill="url(#paint0_linear_1375_1330)"
            stroke="black"
            strokeWidth="0.586521"
          />
        </mask>
        <g mask="url(#mask0_1375_1330)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.6114 167.431H18.0136C17.3814 139.513 38.8624 123.305 57.3067 117.258C58.8027 109.468 58.4205 99.3655 58.4205 99.3655H72.6114H86.8023C86.8023 99.3655 86.42 109.468 87.916 117.258C106.36 123.305 127.841 139.513 127.209 167.431H72.6114Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.5592 76.9775C42.4269 75.5176 36.4552 73.7924 34.4654 78.5689C32.4756 83.3453 36.0575 90.1124 42.8245 90.6438C49.5908 91.1746 47.601 89.9801 47.601 89.9801L42.5592 76.9775Z"
            fill="#E4AE7C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102.811 76.9775C102.943 75.5176 108.914 73.7924 110.904 78.5689C112.894 83.3453 109.312 90.1124 102.545 90.6438C95.7782 91.1746 97.768 89.9801 97.768 89.9801L102.811 76.9775Z"
            fill="#E4AE7C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86.9749 109.385C80.8317 121.137 65.0078 122.363 58.2139 109.838C58.3923 107.276 58.45 104.864 58.4608 103.003H86.758C86.7672 104.754 86.8203 106.993 86.9749 109.385Z"
            fill="#D67F54"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.6093 109.148C37.4027 109.994 19.6267 23.1745 72.6093 25.3981C125.592 23.1745 107.816 109.994 72.6093 109.148Z"
            fill="#E4AE7C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.7849 68.8497C62.1978 69.8696 62.5155 71.8402 61.4941 73.25C60.4711 74.6607 58.4974 74.9776 57.0837 73.9584C55.67 72.9377 55.3531 70.9679 56.3753 69.5573C57.3968 68.1467 59.3712 67.8306 60.7849 68.8497Z"
            fill="#20201E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M84.634 68.8497C83.2203 69.8696 82.9027 71.8402 83.9249 73.25C84.9463 74.6607 86.9207 74.9776 88.3352 73.9584C89.7474 72.9377 90.0658 70.9679 89.0436 69.5573C88.0214 68.1467 86.047 67.8306 84.634 68.8497Z"
            fill="#20201E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.8924 80.2969C58.6891 81.6767 58.7684 83.6973 57.0685 84.8095C55.3687 85.9217 52.5336 85.7056 50.7361 84.3257C48.9393 82.9466 48.8609 80.926 50.5607 79.8138C52.2605 78.7016 55.0957 78.9178 56.8924 80.2969Z"
            fill="#DA9077"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.5256 80.2969C86.7289 81.6767 86.6504 83.6973 88.3503 84.8095C90.0501 85.9217 92.8852 85.7056 94.6827 84.3257C96.4795 82.9466 96.5579 80.926 94.8581 79.8138C93.1583 78.7016 90.3231 78.9178 88.5256 80.2969Z"
            fill="#DA9077"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.1612 60.2878C62.0443 60.2362 61.9267 60.1885 61.8067 60.1424C60.4306 59.6463 59.2638 59.5117 57.7778 59.6194C56.3226 59.7732 55.2358 60.1024 54.0159 60.7985C53.4459 61.1654 51.2231 62.9644 51.8538 63.629C52.1107 63.6997 54.3251 62.5683 54.9758 62.3568C55.9872 62.0584 56.8079 61.9438 57.8794 61.9668C58.8785 62.0114 59.6307 62.1568 60.5414 62.5083C60.566 62.5129 60.7306 62.5868 60.766 62.6022C62.6227 63.5051 62.4543 64.0851 63.7211 63.2167C65.6717 62.1791 63.6642 61.0685 62.1612 60.2878Z"
            fill="#3F2716"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M83.0608 60.2878C83.1785 60.2362 83.2954 60.1885 83.4154 60.1424C84.7914 59.6463 85.9582 59.5117 87.445 59.6194C88.8995 59.7732 89.9863 60.1024 91.207 60.7985C91.7761 61.1654 93.999 62.9644 93.3683 63.629C93.1114 63.6997 90.897 62.5683 90.2471 62.3568C89.2348 62.0584 88.4142 61.9438 87.3427 61.9668C86.3436 62.0114 85.5921 62.1568 84.6807 62.5083C84.6561 62.5129 84.4915 62.5868 84.4561 62.6022C82.5993 63.5051 82.7678 64.0851 81.501 63.2167C79.5512 62.1791 81.5579 61.0685 83.0608 60.2878Z"
            fill="#3F2716"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70.9576 97.6025C70.7769 97.4963 70.7161 97.2641 70.8223 97.0825C70.9284 96.9018 71.1615 96.841 71.3422 96.9464C71.8675 97.2541 72.5359 97.4079 73.2051 97.4079C73.8735 97.4079 74.5304 97.2548 75.0334 96.9495C75.2126 96.841 75.4464 96.8987 75.5556 97.0779C75.6641 97.2571 75.6064 97.491 75.4272 97.6002C74.7996 97.9809 74.002 98.1709 73.2051 98.1709C72.4098 98.1709 71.6045 97.9817 70.9576 97.6025Z"
            fill="#D67F54"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.739 82.3387C108.739 82.5487 108.568 82.7202 108.358 82.7202C108.146 82.7202 107.975 82.5487 107.975 82.3387C107.975 81.1788 107.775 80.4504 107.453 80.0535C107.259 79.8159 107.014 79.7059 106.743 79.6997C106.431 79.6928 106.074 79.8097 105.703 80.0197C104.701 80.5873 103.665 81.7903 103.092 83.1009C102.816 83.7324 102.578 84.3808 102.441 84.8715C103.027 84.4639 103.674 84.3016 104.22 84.3562C104.53 84.3877 104.813 84.4877 105.042 84.6531C105.283 84.8269 105.464 85.0692 105.555 85.3723C105.711 85.8961 105.59 86.5991 105.028 87.4382C104.911 87.612 104.675 87.6597 104.5 87.5428C104.326 87.4259 104.279 87.1898 104.396 87.0144C104.816 86.3883 104.92 85.9114 104.824 85.5907C104.783 85.4546 104.704 85.3469 104.597 85.2707C104.478 85.1846 104.322 85.1315 104.145 85.1131C103.655 85.0646 103.034 85.2784 102.517 85.7984C102.379 85.9376 102.249 86.016 102.129 86.0414C101.823 86.1076 101.644 85.9737 101.578 85.673L101.574 85.653C101.551 85.5399 101.557 85.3876 101.585 85.2069C101.671 84.6639 101.998 83.704 102.394 82.7971C103.03 81.3426 104.195 79.9989 105.328 79.3575C105.814 79.0829 106.303 78.9283 106.758 78.939C107.253 78.9513 107.697 79.1467 108.043 79.5736C108.472 80.1012 108.739 80.9904 108.739 82.3387Z"
            fill="#D67F54"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.2498 82.3387C37.2498 82.5487 37.0791 82.7202 36.8683 82.7202C36.6576 82.7202 36.4868 82.5487 36.4868 82.3387C36.4868 80.9904 36.7529 80.1012 37.1821 79.5736C37.5298 79.1467 37.9728 78.9513 38.4674 78.939C38.9227 78.9283 39.4119 79.0829 39.898 79.3575C41.031 79.9989 42.1955 81.3426 42.8316 82.7971C43.2277 83.704 43.5546 84.6639 43.6407 85.2069C43.6692 85.3876 43.6746 85.5399 43.6515 85.653L43.6469 85.673C43.5807 85.973 43.4031 86.1076 43.0962 86.0414C42.977 86.016 42.847 85.9376 42.7085 85.7984C42.1916 85.2784 41.5717 85.0646 41.081 85.1131C40.9033 85.1315 40.7472 85.1846 40.628 85.2707C40.521 85.3469 40.4418 85.4546 40.4018 85.5907C40.3057 85.9114 40.4095 86.3883 40.8295 87.0144C40.9472 87.1898 40.9002 87.4259 40.7256 87.5428C40.551 87.6597 40.3149 87.612 40.1972 87.4382C39.6357 86.5991 39.5142 85.8961 39.6711 85.3723C39.7619 85.0692 39.9419 84.8269 40.1834 84.6531C40.4134 84.4877 40.6956 84.3877 41.0064 84.3562C41.5517 84.3016 42.1986 84.4639 42.7839 84.8715C42.6478 84.3808 42.4093 83.7324 42.134 83.1009C41.5609 81.7903 40.5241 80.5873 39.5219 80.0197C39.1519 79.8097 38.7951 79.6928 38.4828 79.6997C38.2113 79.7059 37.9667 79.8159 37.7728 80.0535C37.4506 80.4504 37.2498 81.1788 37.2498 82.3387Z"
            fill="#D67F54"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.573 32.6199C113.04 32.9007 112.848 34.106 108.569 37.1264C112.524 42.1106 113.131 50.0282 111.781 57.456C111.492 59.042 107.029 70.2824 102.824 77.6463C102.433 78.3301 102.694 63.0454 98.2788 56.9399C96.0383 53.8425 94.1915 50.1705 89.6289 47.1108C89.5828 47.98 92.294 50.3521 91.2818 50.8289C87.4122 52.6526 76.5156 50.7689 71.4822 49.4929C72.9305 50.9843 74.4089 52.0657 73.8543 52.6103C72.1214 54.3117 61.1663 51.6558 54.3993 47.1139C52.7594 52.5249 50.4435 58.2705 47.843 59.8011C40.8806 63.8977 43.9157 81.1975 42.0882 78.6555C33.7059 66.9958 35.8195 51.8988 35.8195 51.8988C35.0073 53.1872 34.0828 54.2963 33.4429 55.6277C32.2868 58.0336 31.4954 35.905 42.0136 33.0353C42.0136 33.0353 40.3545 32.4623 36.0864 33.2622C34.5204 33.5568 39.1654 29.2387 44.1757 28.678C44.3195 26.499 45.2925 17.7529 51.688 14.9309C54.6546 13.6218 59.5149 12.7327 58.8981 13.1065C55.8491 14.9532 54.9731 17.5214 54.9731 17.5214C78.3762 6.48173 89.022 18.2175 98.1942 25.5745C100.968 27.7989 106.839 32.25 111.573 32.6199Z"
            fill="#35253D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M64.769 66.2073C63.0723 64.5106 60.7287 63.4614 58.1404 63.4614C55.5522 63.4614 53.2079 64.5106 51.5111 66.2073C49.8159 67.9033 48.7652 70.2477 48.7652 72.8359C48.7652 75.4241 49.8159 77.7677 51.5111 79.4645C53.2079 81.1605 55.5522 82.2104 58.1404 82.2104C60.7287 82.2104 63.0723 81.1605 64.769 79.4645C66.4658 77.7677 67.5149 75.4241 67.5149 72.8359C67.5149 70.2477 66.4658 67.9033 64.769 66.2073ZM58.1404 61.5524C61.2563 61.5524 64.0768 62.8161 66.1181 64.8582C68.1602 66.8988 69.424 69.7201 69.424 72.8359C69.424 75.951 68.1602 78.7723 66.1181 80.8136C64.0768 82.8557 61.2563 84.1187 58.1404 84.1187C55.0254 84.1187 52.2041 82.8557 50.162 80.8136C48.1207 78.7723 46.8569 75.951 46.8569 72.8359C46.8569 69.7201 48.1207 66.8988 50.162 64.8582C52.2041 62.8161 55.0254 61.5524 58.1404 61.5524Z"
            fill="#2E1E11"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.7114 66.2073C92.0147 64.5106 89.671 63.4614 87.0828 63.4614C84.4939 63.4614 82.1502 64.5106 80.4542 66.2073C78.7575 67.9033 77.7084 70.2477 77.7084 72.8359C77.7084 75.4241 78.7575 77.7677 80.4542 79.4645C82.1502 81.1605 84.4939 82.2104 87.0828 82.2104C89.671 82.2104 92.0147 81.1605 93.7114 79.4645C95.4074 77.7677 96.4573 75.4241 96.4573 72.8359C96.4573 70.2477 95.4074 67.9033 93.7114 66.2073ZM87.0828 61.5524C90.1979 61.5524 93.0192 62.8161 95.0605 64.8582C97.1026 66.8988 98.3656 69.7201 98.3656 72.8359C98.3656 75.951 97.1026 78.7723 95.0605 80.8136C93.0192 82.8557 90.1979 84.1187 87.0828 84.1187C83.967 84.1187 81.1465 82.8557 79.1044 80.8136C77.0623 78.7723 75.7993 75.951 75.7993 72.8359C75.7993 69.7201 77.0623 66.8988 79.1044 64.8582C81.1465 62.8161 83.967 61.5524 87.0828 61.5524Z"
            fill="#2E1E11"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.474 71.9116C47.8686 71.7693 48.3047 71.9754 48.4463 72.3715C48.5878 72.7653 48.3824 73.2014 47.9871 73.343L41.267 75.7643C40.8716 75.905 40.437 75.6996 40.2947 75.3035C40.1532 74.909 40.3586 74.4736 40.7539 74.3313L47.474 71.9116Z"
            fill="#2E1E11"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M97.2642 73.3434C96.8688 73.2011 96.6635 72.7658 96.805 72.3712C96.9465 71.9759 97.3819 71.7697 97.7772 71.912L104.497 74.3318C104.893 74.4741 105.098 74.9094 104.957 75.304C104.815 75.6993 104.379 75.9055 103.984 75.7639L97.2642 73.3434Z"
            fill="#2E1E11"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.6348 71.9555C68.4156 72.3147 67.9464 72.4277 67.588 72.2085C67.2288 71.9893 67.1157 71.5217 67.3349 71.1625C67.338 71.1563 68.6286 68.9658 72.6106 68.9658C76.5932 68.9658 77.8831 71.1563 77.887 71.1625C78.1054 71.5217 77.9931 71.9893 77.6331 72.2085C77.2747 72.4277 76.8063 72.3147 76.5863 71.9555C76.5848 71.9509 75.7303 70.4933 72.6106 70.4933C69.4916 70.4933 68.6363 71.9509 68.6348 71.9555Z"
            fill="#2E1E11"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M84.553 87.4037C79.7596 89.1828 65.1926 89.1474 60.6699 87.6183C63.1689 96.7236 82.1232 96.5798 84.553 87.4037Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.912 107.785C57.5221 107.618 56.5206 107.632 56.5214 108.338C56.5229 109.101 57.2367 111.726 56.7252 113.666C56.1853 115.715 55.2784 117.397 56.3237 117.804C61.1333 119.673 66.3727 120.661 71.6376 120.767C77.5409 120.887 83.4742 119.899 88.8682 117.804C89.9135 117.397 89.0059 115.715 88.4667 113.666C87.9553 111.726 88.669 109.101 88.6706 108.338C88.6713 107.632 87.6699 107.618 87.2799 107.785C77.8939 111.807 67.298 111.807 57.912 107.785Z"
            fill="#67627E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.1151 109.569C59.122 109.36 59.2974 109.194 59.5066 109.2C59.7173 109.207 59.8819 109.383 59.875 109.593C59.8581 110.113 59.8527 110.601 59.8473 111.093C59.8358 112.102 59.8242 113.128 59.7235 114.332C59.5643 116.243 58.539 117.834 58.5359 117.838C58.4236 118.015 58.1883 118.068 58.0106 117.954C57.8344 117.842 57.7821 117.606 57.8952 117.43C57.8975 117.426 58.8197 115.995 58.9636 114.27C59.0651 113.055 59.0759 112.064 59.0874 111.088C59.0928 110.581 59.0989 110.079 59.1151 109.569Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.2474 110.682C62.2543 110.473 62.4297 110.307 62.6397 110.314C62.8497 110.321 63.0143 110.496 63.0073 110.706C62.9904 111.226 62.985 111.714 62.9796 112.207C62.9681 113.216 62.9566 114.241 62.8566 115.445C62.6974 117.356 61.6713 118.947 61.6682 118.951C61.5559 119.128 61.3206 119.181 61.1429 119.067C60.9668 118.954 60.9145 118.72 61.0275 118.543C61.0298 118.539 61.9521 117.108 62.0959 115.383C62.1974 114.169 62.2082 113.177 62.2197 112.201C62.2251 111.694 62.2313 111.192 62.2474 110.682Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.5284 111.287C65.5353 111.077 65.7099 110.912 65.9199 110.918C66.1306 110.925 66.2952 111.101 66.2883 111.31C66.2714 111.83 66.266 112.319 66.2606 112.81C66.2491 113.819 66.2375 114.845 66.1368 116.05C65.9775 117.961 64.9523 119.551 64.9492 119.556C64.8369 119.732 64.6015 119.785 64.4239 119.672C64.2469 119.559 64.1954 119.324 64.3085 119.148C64.3108 119.144 65.233 117.712 65.3768 115.987C65.4776 114.773 65.4891 113.781 65.4999 112.806C65.5053 112.299 65.5122 111.797 65.5284 111.287Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.9129 111.895C68.9199 111.685 69.0952 111.52 69.3044 111.527C69.5152 111.534 69.6798 111.709 69.6729 111.918C69.6559 112.438 69.6506 112.927 69.6452 113.419C69.6336 114.428 69.6221 115.453 69.5213 116.658C69.3629 118.569 68.3368 120.159 68.3338 120.164C68.2215 120.341 67.9861 120.394 67.8084 120.28C67.6323 120.167 67.58 119.933 67.6931 119.756C67.6954 119.752 68.6176 118.321 68.7614 116.595C68.8629 115.382 68.8737 114.39 68.8852 113.413C68.8906 112.907 68.8968 112.405 68.9129 111.895Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M84.9528 109.593C84.9467 109.383 85.1112 109.207 85.3212 109.2C85.5312 109.193 85.7066 109.359 85.7135 109.569C85.7304 110.079 85.7358 110.581 85.7412 111.087C85.7527 112.064 85.7643 113.055 85.865 114.27C86.0089 115.995 86.9303 117.426 86.9334 117.429C87.0464 117.606 86.9934 117.842 86.8172 117.954C86.6403 118.068 86.405 118.015 86.2919 117.838C86.2896 117.834 85.2628 116.243 85.1043 114.332C85.0043 113.128 84.992 112.102 84.9813 111.093C84.9751 110.601 84.9697 110.113 84.9528 109.593Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M81.8205 110.706C81.8143 110.496 81.9789 110.321 82.1889 110.314C82.3989 110.307 82.5743 110.472 82.5812 110.682C82.5981 111.192 82.6035 111.694 82.6089 112.2C82.6204 113.177 82.6319 114.169 82.7327 115.383C82.8765 117.108 83.798 118.539 83.8011 118.542C83.9141 118.72 83.861 118.954 83.6849 119.067C83.508 119.181 83.2719 119.128 83.1596 118.951C83.1573 118.947 82.1304 117.356 81.972 115.445C81.8712 114.241 81.8597 113.216 81.8489 112.207C81.8428 111.714 81.8374 111.226 81.8205 110.706Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M78.5397 111.31C78.5336 111.101 78.6982 110.925 78.9081 110.918C79.1181 110.911 79.2935 111.076 79.3004 111.287C79.3173 111.797 79.3227 112.299 79.3281 112.805C79.3396 113.781 79.3512 114.773 79.4519 115.987C79.5958 117.712 80.518 119.144 80.5203 119.147C80.6334 119.324 80.5811 119.559 80.4042 119.672C80.2272 119.785 79.9919 119.732 79.8788 119.556C79.8765 119.551 78.8505 117.96 78.6912 116.05C78.5913 114.845 78.5797 113.819 78.5682 112.81C78.5628 112.319 78.5566 111.83 78.5397 111.31Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.155 111.918C75.1488 111.709 75.3134 111.534 75.5234 111.526C75.7334 111.519 75.9087 111.684 75.9156 111.895C75.9326 112.405 75.938 112.907 75.9433 113.413C75.9549 114.39 75.9664 115.382 76.0672 116.595C76.211 118.321 77.1325 119.752 77.1355 119.755C77.2486 119.933 77.1955 120.167 77.0194 120.28C76.8425 120.394 76.6071 120.341 76.4941 120.164C76.4917 120.159 75.4649 118.568 75.3065 116.658C75.2065 115.453 75.1942 114.428 75.1834 113.419C75.1773 112.927 75.1719 112.438 75.155 111.918Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.0767 111.907C72.0767 111.697 72.2474 111.526 72.4574 111.526C72.6674 111.526 72.8374 111.697 72.8374 111.907L72.8543 119.96C72.8543 120.169 72.6843 120.34 72.4743 120.34C72.2643 120.34 72.0944 120.169 72.0944 119.96L72.0767 111.907Z"
            fill="#58556C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.7606 29.6757C51.6614 29.4903 51.7306 29.2595 51.916 29.1596C52.1006 29.0596 52.3329 29.1296 52.4321 29.3142C54.5288 33.203 58.8853 36.6234 63.9071 39.3486C70.3142 42.8244 77.7827 45.1534 83.0522 45.8825C83.2606 45.911 83.406 46.1033 83.3767 46.3117C83.3483 46.5194 83.156 46.6655 82.9476 46.6371C77.5996 45.8972 70.0281 43.5374 63.5433 40.0193C58.4053 37.2326 53.9365 33.7107 51.7606 29.6757Z"
            fill="#432D4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.0358 26.3396C55.825 26.3396 55.6543 26.1688 55.6543 25.9588C55.6543 25.7473 55.825 25.5773 56.0358 25.5773C62.1721 25.5773 67.8862 26.6411 73.0934 28.3717C79.2274 30.4092 84.6507 33.3704 89.2272 36.6032C94.0498 40.0098 97.9417 43.8571 100.743 48.522C103.545 53.1877 105.251 58.6618 105.7 65.3204C105.714 65.5296 105.555 65.7119 105.345 65.7257C105.135 65.7396 104.953 65.5811 104.939 65.3711C104.499 58.8395 102.83 53.4754 100.09 48.9127C97.3479 44.3478 93.5291 40.5743 88.7888 37.2262C84.2638 34.0296 78.9059 31.1037 72.8549 29.0931C67.7254 27.3887 62.0921 26.3396 56.0358 26.3396Z"
            fill="#432D4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.822 22.8693C58.6312 22.9577 58.4043 22.8746 58.3167 22.6831C58.229 22.4931 58.312 22.2662 58.5028 22.1786C74.159 14.9669 84.3526 22.1439 93.144 28.3333C95.3161 29.8632 97.4021 31.3315 99.4488 32.4945C99.6326 32.5975 99.6965 32.8298 99.5919 33.0129C99.4888 33.1959 99.2565 33.2605 99.0735 33.1559C96.9875 31.9722 94.89 30.4947 92.7048 28.9564C84.0803 22.8846 74.079 15.8422 58.822 22.8693Z"
            fill="#432D4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.6219 36.0785C48.6111 35.8685 48.7727 35.6893 48.9826 35.6785C49.1926 35.6685 49.3718 35.83 49.3826 36.0392C49.4926 38.0867 48.9103 40.998 47.9074 43.8746C46.9367 46.6582 45.5699 49.4202 44.0554 51.3424C41.1096 55.0797 41.0642 59.6831 41.0642 59.6969C41.0611 59.9069 40.8873 60.0746 40.6781 60.0707C40.4681 60.0677 40.3004 59.8954 40.3043 59.6846C40.3043 59.6715 40.352 54.8097 43.4555 50.8709C44.9192 49.0141 46.2445 46.3328 47.189 43.6239C48.1612 40.8349 48.7273 38.0298 48.6219 36.0785Z"
            fill="#432D4C"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1375_1330"
          x="15.6539"
          y="13"
          width="113.915"
          height="141.307"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.34608" />
          <feGaussianBlur stdDeviation="1.17304" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1375_1330"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1375_1330"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1375_1330"
          x1="0.584961"
          y1="0.63916"
          x2="160.827"
          y2="13.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D19A08" />
          <stop offset="0.276042" stopColor="#FEDB7E" />
          <stop offset="0.5" stopColor="#EAC460" />
          <stop offset="0.661458" stopColor="#D19A08" />
          <stop offset="1" stopColor="#D19A08" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MaleAvatar;
