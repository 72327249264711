import React from "react";

export default function S() {
  return (
    <div>
      <svg
        width="79"
        height="94"
        viewBox="0 0 79 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.9395 93.536C31.6008 93.536 24.5182 92.6827 17.6915 90.976C10.9502 89.2693 5.36083 86.9653 0.9235 84.064L8.4755 62.816C11.2062 64.4373 14.2782 65.888 17.6915 67.168C21.1048 68.3627 24.6462 69.344 28.3155 70.112C31.9848 70.7947 35.6115 71.136 39.1955 71.136C44.2302 71.136 47.6008 70.624 49.3075 69.6C51.0142 68.4907 51.8675 67.168 51.8675 65.632C51.8675 64.1813 51.3128 63.0293 50.2035 62.176C49.1795 61.3227 47.0035 60.5547 43.6755 59.872L27.4195 56.544C19.1422 54.8373 12.9128 51.808 8.7315 47.456C4.6355 43.104 2.5875 37.472 2.5875 30.56C2.5875 24.416 4.2515 19.0827 7.5795 14.56C10.9075 10.0373 15.6008 6.53866 21.6595 4.064C27.7182 1.504 34.8008 0.223999 42.9075 0.223999C49.3075 0.223999 55.3662 1.07733 61.0835 2.784C66.8008 4.49066 71.4515 6.79466 75.0355 9.696L67.4835 29.664C64.2408 27.4453 60.4862 25.7387 56.2195 24.544C52.0382 23.264 47.3875 22.624 42.2675 22.624C38.0008 22.624 34.8008 23.136 32.6675 24.16C30.6195 25.184 29.5955 26.6773 29.5955 28.64C29.5955 29.8347 30.1928 30.944 31.3875 31.968C32.5822 32.992 34.8862 33.8453 38.2995 34.528L54.2995 37.728C62.4062 39.3493 68.5075 42.336 72.6035 46.688C76.7848 51.04 78.8755 56.6293 78.8755 63.456C78.8755 69.5147 77.2115 74.8053 73.8835 79.328C70.5555 83.8507 65.9048 87.3493 59.9315 89.824C53.9582 92.2987 46.9608 93.536 38.9395 93.536Z"
          fill="url(#paint0_linear_2283_3095)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2283_3095"
            x1="-4"
            y1="-37"
            x2="91.1115"
            y2="-32.6829"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D19A08" />
            <stop offset="0.276042" stopColor="#FEDB7E" />
            <stop offset="0.5" stopColor="#EAC460" />
            <stop offset="0.661458" stopColor="#D19A08" />
            <stop offset="1" stopColor="#D19A08" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
