import React from "react";
import iconsCSS from "./icon.module.css";

export default function Back() {
  return (
    <div>
      <svg
        className={iconsCSS.back}
        width="104"
        height="104"
        viewBox="0 0 104 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.6253 0C25.2236 0 26.8721 11.4494 26.8721 11.4494L26.9014 23.3112H52.0961V26.8725H16.8945C16.8945 26.8725 0 24.9565 0 51.5956C0 78.2356 14.7459 77.2908 14.7459 77.2908H23.5462V64.9288C23.5462 64.9288 23.0718 50.1829 38.0567 50.1829H63.0454C63.0454 50.1829 77.0847 50.4098 77.0847 36.6143V13.8044C77.0847 13.8044 79.217 0 51.6253 0ZM37.733 7.97688C38.3284 7.97635 38.918 8.09322 39.4681 8.32081C40.0183 8.5484 40.5182 8.88224 40.9392 9.30323C41.3601 9.72422 41.694 10.2241 41.9216 10.7742C42.1492 11.3244 42.266 11.914 42.2655 12.5094C42.266 13.1048 42.1492 13.6944 41.9216 14.2445C41.694 14.7947 41.3601 15.2946 40.9392 15.7155C40.5182 16.1365 40.0183 16.4704 39.4681 16.698C38.918 16.9256 38.3284 17.0424 37.733 17.0419C37.1376 17.0424 36.548 16.9256 35.9978 16.698C35.4477 16.4704 34.9478 16.1365 34.5268 15.7155C34.1059 15.2946 33.772 14.7947 33.5444 14.2445C33.3168 13.6944 33.2 13.1048 33.2005 12.5094C33.2 11.914 33.3168 11.3244 33.5444 10.7742C33.772 10.2241 34.1059 9.72422 34.5268 9.30323C34.9478 8.88224 35.4477 8.5484 35.9978 8.32081C36.548 8.09322 37.1376 7.97635 37.733 7.97688V7.97688Z"
          fill="url(#paint0_linear_2287_3103)"
        />
        <path
          d="M52.375 103.459C78.7767 103.459 77.1282 92.0099 77.1282 92.0099L77.0989 80.1484H51.9038V76.5872H87.1054C87.1054 76.5872 104 78.5032 104 51.8632C104 25.2237 89.254 26.1685 89.254 26.1685H80.4537V38.53C80.4537 38.53 80.9281 53.2759 65.9431 53.2759H40.9545C40.9545 53.2759 26.9151 53.0491 26.9151 66.8449V89.6553C26.9151 89.6553 24.7833 103.459 52.3745 103.459H52.375ZM66.2673 95.4832C65.6719 95.4837 65.0823 95.3669 64.5322 95.1393C63.982 94.9117 63.4821 94.5778 63.0611 94.1568C62.6402 93.7359 62.3063 93.236 62.0787 92.6858C61.8511 92.1357 61.7343 91.5461 61.7348 90.9507C61.7342 90.3553 61.851 89.7656 62.0786 89.2154C62.3062 88.6652 62.64 88.1653 63.061 87.7443C63.482 87.3232 63.9819 86.9894 64.5321 86.7617C65.0822 86.5341 65.6719 86.4172 66.2673 86.4178C66.8627 86.4172 67.4523 86.5341 68.0024 86.7617C68.5526 86.9893 69.0525 87.3231 69.4735 87.7441C69.8944 88.1651 70.2283 88.665 70.4559 89.2151C70.6835 89.7653 70.8003 90.3549 70.7998 90.9503C70.8003 91.5456 70.6835 92.1353 70.4559 92.6854C70.2283 93.2356 69.8944 93.7354 69.4735 94.1564C69.0525 94.5774 68.5526 94.9113 68.0024 95.1389C67.4523 95.3664 66.8627 95.4833 66.2673 95.4828V95.4832Z"
          fill="url(#paint1_linear_2287_3103)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2287_3103"
            x1="9.99437"
            y1="9.30554"
            x2="61.5336"
            y2="60.3306"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#387EB8" />
            <stop offset="1" stopColor="#366994" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2287_3103"
            x1="41.629"
            y1="42.0708"
            x2="96.9762"
            y2="94.3923"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFE052" />
            <stop offset="1" stopColor="#FFC331" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
