import React from "react";

export default function O() {
  return (
    <div>
      <svg
        width="95"
        height="94"
        viewBox="0 0 95 94"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.623 46.816C0.623 37.5147 2.50033 29.3653 6.255 22.368C10.095 15.3707 15.5137 9.952 22.511 6.112C29.5083 2.18667 37.7857 0.223999 47.343 0.223999C56.9857 0.223999 65.3057 2.18667 72.303 6.112C79.3003 9.952 84.719 15.3707 88.559 22.368C92.399 29.3653 94.319 37.5147 94.319 46.816C94.319 56.1173 92.399 64.2667 88.559 71.264C84.719 78.2613 79.3003 83.7227 72.303 87.648C65.3057 91.5733 56.9857 93.536 47.343 93.536C37.7857 93.536 29.5083 91.5733 22.511 87.648C15.5137 83.7227 10.095 78.2613 6.255 71.264C2.50033 64.1813 0.623 56.032 0.623 46.816ZM29.551 46.816C29.551 54.3253 30.959 60.1707 33.775 64.352C36.6763 68.5333 41.199 70.624 47.343 70.624C53.487 70.624 58.0097 68.5333 60.911 64.352C63.8977 60.0853 65.391 54.24 65.391 46.816C65.391 39.392 63.8977 33.5893 60.911 29.408C58.0097 25.2267 53.487 23.136 47.343 23.136C41.199 23.136 36.6763 25.2267 33.775 29.408C30.959 33.504 29.551 39.3067 29.551 46.816Z"
          fill="url(#paint0_linear_2283_3091)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2283_3091"
            x1="-5"
            y1="-37"
            x2="108.387"
            y2="-30.8592"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D19A08" />
            <stop offset="0.276042" stopColor="#FEDB7E" />
            <stop offset="0.5" stopColor="#EAC460" />
            <stop offset="0.661458" stopColor="#D19A08" />
            <stop offset="1" stopColor="#D19A08" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
