import React from "react";

function FemaleAvatar() {
  return (
    <svg
      width="166"
      height="166"
      viewBox="0 0 150 155"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1375_1342)">
        <mask
          id="mask0_1375_1342"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="150"
          height="150"
        >
          <circle
            cx="74.6649"
            cy="75.1273"
            r="74.1949"
            fill="url(#paint0_linear_1375_1342)"
            stroke="black"
            strokeWidth="0.586521"
          />
        </mask>
        <g mask="url(#mask0_1375_1342)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.1681 122.265C81.1255 138.164 90.7695 147.111 107.728 151.394C103.388 145.025 104.917 135.575 112.242 131.525C119.566 127.476 127.615 123.496 127.597 115.337C127.569 102.612 117.923 104.063 123.499 95.8205C129.076 87.5774 129.48 73.6284 119.381 66.9012C119.238 66.8064 119.103 66.7108 118.964 66.6153C117.065 64.7859 115.712 64.3189 117.87 60.6716C132.095 36.6303 89.0036 13.1363 76.4862 32.5894C73.7417 28.7152 68.7667 24.6521 60.1846 25.6313C54.8481 26.2398 41.2009 31.8142 35.1375 41.8011C25.0595 58.3997 40.0674 58.1077 32.8064 66.7169C32.0619 67.3247 31.3029 68.0005 30.7019 68.823C30.6141 68.8964 30.5264 68.9698 30.4379 69.0401C20.9351 76.5295 23.2608 87.5774 28.8376 95.8205C34.4136 104.063 23.598 104.184 23.4073 114.91C23.2105 125.922 34.6958 128.046 42.0202 132.095C49.3453 136.145 48.9486 145.025 44.6091 151.394C61.5667 147.111 71.2107 138.164 76.1681 122.265Z"
            fill="#39271F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.5 167H130C130 115.632 89.347 143.346 89.347 100.551H75.5H61.4524C61.4524 143.346 21 115.632 21 167H75.5Z"
            fill="#E4AE7C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.6957 74.5277C45.5645 73.0776 39.6422 71.3629 37.6688 76.1102C35.6955 80.8574 39.2478 87.5831 45.9589 88.1113C52.6692 88.638 50.6958 87.4508 50.6958 87.4508L45.6957 74.5277Z"
            fill="#E4AE7C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M89.5725 99.349C89.5725 99.349 89.4978 101.34 89.5451 104.231C84.1994 117.691 67.6018 119.205 61.4431 104.791C61.5109 101.599 61.4263 99.349 61.4263 99.349H89.5725Z"
            fill="#D67F54"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.497 106.502C40.5817 107.343 22.9528 21.054 75.497 23.2641C128.041 21.054 110.412 107.343 75.497 106.502Z"
            fill="#E4AE7C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.7539 68.6142C59.8043 69.134 59.4252 69.5965 58.9072 69.6477C58.3878 69.6989 57.9263 69.319 57.8759 68.7999C57.8264 68.3099 57.8645 67.813 57.9927 67.3352C58.1193 66.8689 58.329 66.4171 58.6273 66.0036C59.2864 65.0931 60.2543 64.535 61.2864 64.3684C62.3139 64.2033 63.4054 64.4303 64.317 65.0893C64.8982 65.5097 65.3368 66.0563 65.6213 66.6648C65.9165 67.2947 66.0462 67.9896 65.9989 68.6769C65.9638 69.1982 65.5138 69.5934 64.9936 69.5583C64.4733 69.5231 64.0782 69.0728 64.1133 68.5507C64.1392 68.1754 64.0706 67.8 63.9135 67.4644C63.7617 67.1403 63.5275 66.8483 63.2155 66.6235C62.7258 66.2688 62.1369 66.1465 61.5816 66.236C61.0309 66.3246 60.5122 66.6251 60.1582 67.1158C59.998 67.3368 59.8859 67.5768 59.8195 67.8245C59.7486 68.0867 59.728 68.3543 59.7539 68.6142Z"
            fill="#39271F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.9104 76.2325C61.6923 77.6032 61.7709 79.6107 60.0851 80.7176C58.3994 81.823 55.5877 81.6082 53.8051 80.2375C52.0232 78.8661 51.9454 76.8579 53.6311 75.7525C55.3169 74.6471 58.1286 74.8611 59.9104 76.2325Z"
            fill="#DA9077"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.2816 76.2325C89.4997 77.6032 89.4219 79.6107 91.1077 80.7176C92.7935 81.823 95.6051 81.6082 97.3878 80.2375C99.1697 78.8661 99.2475 76.8579 97.5617 75.7525C95.8759 74.6471 93.0643 74.8611 91.2816 76.2325Z"
            fill="#DA9077"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.1356 56.3455C65.0197 56.295 64.9029 56.2468 64.784 56.2017C63.4193 55.7087 62.2622 55.5749 60.7884 55.6819C59.3452 55.8348 58.2674 56.1612 57.0576 56.853C56.4924 57.2185 54.2879 59.0073 54.9134 59.6655C55.1682 59.7373 57.3643 58.6128 58.0096 58.4026C59.0127 58.106 59.8266 57.9921 60.8891 58.0143C61.88 58.0586 62.626 58.2038 63.5292 58.5524C63.5536 58.5578 63.7168 58.6304 63.7519 58.6465C65.5933 59.5439 65.4262 60.1196 66.6825 59.2573C68.617 58.2245 66.6261 57.1221 65.1356 56.3455Z"
            fill="#3F2716"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M85.8621 56.3455C85.9788 56.295 86.0947 56.2468 86.2137 56.2017C87.5783 55.7087 88.7355 55.5749 90.21 55.6819C91.6524 55.8348 92.7302 56.1612 93.9408 56.853C94.5053 57.2185 96.7097 59.0073 96.0842 59.6655C95.8295 59.7373 93.6334 58.6128 92.9888 58.4026C91.985 58.106 91.1711 57.9921 90.1085 58.0143C89.1177 58.0586 88.3724 58.2038 87.4685 58.5524C87.4441 58.5578 87.2808 58.6304 87.2458 58.6465C85.4044 59.5439 85.5714 60.1196 84.3151 59.2573C82.3814 58.2245 84.3716 57.1221 85.8621 56.3455Z"
            fill="#3F2716"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.4414 83.5514C69.6596 83.5116 72.8656 83.491 76.0709 83.491C79.2769 83.491 82.4829 83.5116 85.7012 83.5514C85.7012 88.6113 80.7216 90.4185 76.0884 90.4185H76.0709H76.0541C71.4202 90.4185 66.4414 88.6113 66.4414 83.5514Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M73.8589 93.4321C73.6797 93.3266 73.6194 93.095 73.7247 92.9146C73.8299 92.7342 74.0611 92.6746 74.2403 92.7793C74.7613 93.0851 75.4242 93.2387 76.0878 93.2387C76.7507 93.2387 77.4021 93.0866 77.901 92.7824C78.0787 92.6753 78.3106 92.7319 78.4189 92.91C78.5264 93.0889 78.4692 93.3213 78.2915 93.4291C77.6691 93.8083 76.878 93.9971 76.0878 93.9971C75.2991 93.9971 74.5004 93.8083 73.8589 93.4321Z"
            fill="#D67F54"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.2128 76.6084C75.0046 76.6084 74.8345 76.4379 74.8345 76.2285C74.8345 76.0198 75.0046 75.8493 75.2128 75.8493C76.1213 75.8493 77.2075 75.8409 78.0451 75.6987C78.7354 75.5817 79.2419 75.3944 79.2419 75.0795C79.2419 73.9886 78.1381 67.2033 78.1351 67.185C78.103 66.9786 78.2434 66.7844 78.4493 66.7515C78.6545 66.7187 78.8483 66.8601 78.8811 67.0657C78.8834 67.0856 79.9986 73.9412 79.9986 75.0795C79.9986 75.8783 79.2236 76.2667 78.1694 76.4456C77.2663 76.5992 76.148 76.6084 75.2128 76.6084Z"
            fill="#D67F54"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.43 79.8566C40.43 80.0661 40.2607 80.2365 40.0517 80.2365C39.8427 80.2365 39.6733 80.0661 39.6733 79.8566C39.6733 78.5173 39.9373 77.6336 40.3629 77.1092C40.7077 76.6857 41.1471 76.4899 41.6375 76.4792C42.0891 76.4693 42.5742 76.6207 43.0563 76.8943C44.1799 77.5327 45.3348 78.8682 45.9656 80.313C46.3585 81.215 46.6826 82.1698 46.7681 82.7088C46.7963 82.8877 46.8016 83.0383 46.7788 83.1514L46.7742 83.1713C46.7086 83.4694 46.5324 83.6032 46.228 83.5382C46.1098 83.5122 45.9809 83.4343 45.8436 83.2959C45.331 82.7791 44.7162 82.5666 44.2295 82.6148C44.0533 82.6323 43.8984 82.6859 43.7802 82.7715C43.6742 82.8472 43.5956 82.9534 43.556 83.0887C43.4606 83.4083 43.5636 83.8822 43.9801 84.5053C44.0968 84.6788 44.0502 84.9135 43.8771 85.0297C43.7039 85.1451 43.4698 85.0992 43.3531 84.9257C42.7962 84.0917 42.6757 83.393 42.8313 82.8724C42.9213 82.5712 43.0998 82.3304 43.3393 82.1584C43.5674 81.994 43.8473 81.8939 44.1555 81.8625C44.6963 81.8075 45.3378 81.9696 45.9183 82.3747C45.7833 81.887 45.5468 81.2426 45.2738 80.6149C44.7055 79.3131 43.6772 78.1167 42.6833 77.5525C42.3164 77.3438 41.9625 77.2276 41.6528 77.2345C41.3835 77.2406 41.141 77.3492 40.9487 77.5869C40.6291 77.9806 40.43 78.7053 40.43 79.8566Z"
            fill="#D67F54"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.1868 79.9058C42.6666 73.0211 42.72 65.3314 42.3889 62.3768C39.1425 33.3994 57.1505 19.6339 74.9403 20.143C75.1272 20.1377 75.3126 20.1346 75.4979 20.1331V31.4898C72.2347 30.4173 69.4528 29.7675 67.9798 29.7675C67.7983 29.7675 67.6183 29.7644 67.4382 29.7575C67.2598 29.7644 67.0797 29.7675 66.8982 29.7675C62.7989 29.7675 60.7623 36.6178 56.0429 38.4723C53.338 39.5342 55.9269 52.0674 53.1298 58.3741C52.4448 59.9183 48.8681 63.5334 48.0412 63.8981C45.6216 66.3673 45.3684 69.1377 46.1868 79.9058Z"
            fill="#39271F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.2809 153.95C39.2534 154.839 38.5112 155.538 37.6241 155.51C36.7362 155.482 36.039 154.739 36.0672 153.85L39.2687 130.43C39.2961 129.542 40.0383 128.843 40.9255 128.871C41.8134 128.899 42.509 129.642 42.4823 130.532L39.2809 153.95Z"
            fill="#2E1E11"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M111.714 153.95C111.742 154.839 112.484 155.538 113.371 155.51C114.259 155.482 114.956 154.739 114.928 153.85L111.727 130.43C111.698 129.542 110.957 128.843 110.07 128.871C109.183 128.899 108.486 129.642 108.513 130.532L111.714 153.95Z"
            fill="#2E1E11"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M87.0805 68.6142C87.1316 69.134 86.7525 69.5965 86.2338 69.6477C85.7151 69.6989 85.2536 69.319 85.2032 68.7999C85.1536 68.3099 85.191 67.813 85.3199 67.3352C85.4458 66.8689 85.6556 66.4171 85.9546 66.0036C86.6136 65.0931 87.5809 64.535 88.6129 64.3684C89.6412 64.2033 90.7327 64.4303 91.6442 65.0893C92.2255 65.5097 92.6633 66.0563 92.9479 66.6648C93.2431 67.2947 93.3727 67.9896 93.3254 68.6769C93.2904 69.1982 92.8403 69.5934 92.3201 69.5583C91.7999 69.5231 91.4055 69.0728 91.4398 68.5507C91.4658 68.1754 91.3971 67.8 91.2407 67.4644C91.0882 67.1403 90.854 66.8483 90.5428 66.6235C90.0523 66.2688 89.4642 66.1465 88.9081 66.236C88.3581 66.3246 87.8394 66.6251 87.4847 67.1158C87.3246 67.3368 87.2124 67.5768 87.1461 67.8245C87.0759 68.0867 87.0545 68.3543 87.0805 68.6142Z"
            fill="#39271F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.7583 40.2705C70.5488 29.2433 63.7478 48.7996 77.0852 48.8515C83.7238 48.8775 89.8033 51.5646 89.6942 57.2086C89.5859 62.8525 89.5454 65.3531 95.6348 68.284C107.437 73.9623 84.9145 93.5339 114.474 94.624C111.383 80.3226 126.048 75.6984 121.088 63.289C116.127 50.8796 106.535 54.4978 108.624 46.7921C110.268 40.7277 110.958 34.1679 106.612 29.5032C100.335 22.7653 94.1275 29.6309 90.1076 23.146C86.7353 17.7038 84.7627 9.63351 70.5122 12.6585C52.3677 16.5098 51.6003 31.0413 54.7583 40.2705Z"
            fill="#432E24"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.7929 33.4954C56.6731 33.6651 56.4374 33.7064 56.2673 33.5864C56.0979 33.4656 56.0575 33.2301 56.1773 33.0604C56.7776 32.2096 58.2048 31.2785 59.9302 30.6768C61.1476 30.2526 62.5252 29.985 63.8761 30.0194C65.2476 30.0538 66.594 30.3994 67.726 31.2028C68.7206 31.9092 69.5429 32.9618 70.0624 34.4578C71.2363 37.8413 72.3233 39.7196 73.8283 40.7722C75.3287 41.8234 77.3043 42.0871 80.2396 42.2346C83.2885 42.3875 86.927 42.8737 89.8584 44.5028C92.8325 46.1563 95.0667 48.9641 95.2216 53.7427C95.3703 58.2851 95.902 60.8117 96.8776 62.3467C97.825 63.8343 99.2438 64.4099 101.172 65.0376C101.314 65.0834 101.444 65.1285 101.561 65.1729C103.448 65.8739 104.85 67.0649 105.548 68.8193C106.235 70.5447 106.223 72.8006 105.299 75.652C105.209 75.9303 105.114 76.2032 105.016 76.4707C103.916 79.4414 103.654 82.1216 104.073 84.2521C104.5 86.4262 105.638 88.0186 107.312 88.7379C107.503 88.8197 107.593 89.0422 107.512 89.2348C107.43 89.4267 107.208 89.5162 107.017 89.4336C105.103 88.6126 103.808 86.8245 103.331 84.3966C102.887 82.1361 103.155 79.313 104.306 76.207C104.409 75.9272 104.5 75.665 104.58 75.4181C105.448 72.7448 105.47 70.6616 104.848 69.0976C104.237 67.5618 102.988 66.5122 101.298 65.8838C101.169 65.8364 101.049 65.7936 100.939 65.7584C98.8593 65.0804 97.3231 64.4512 96.2422 62.7526C95.1903 61.0998 94.6205 58.4579 94.4679 53.7664C94.323 49.3104 92.2505 46.6975 89.4915 45.1633C86.6882 43.6061 83.1649 43.139 80.2045 42.9899C77.1312 42.8363 75.0503 42.5488 73.3966 41.3914C71.7459 40.2371 70.5796 38.2503 69.3492 34.7063C68.8854 33.37 68.1623 32.4374 67.2912 31.8197C66.2934 31.1111 65.0905 30.806 63.8578 30.7755C62.6038 30.7434 61.3193 30.9941 60.1789 31.3916C58.5946 31.9436 57.3085 32.7653 56.7929 33.4954Z"
            fill="#774D30"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.6569 22.5789C60.4692 22.6706 60.2427 22.5927 60.1512 22.4039C60.0596 22.2158 60.1374 21.9895 60.3258 21.897C61.0383 21.5492 61.8873 21.2296 62.8163 20.9713C64.8988 20.3941 67.3809 20.1304 69.6105 20.5523C71.8898 20.9827 73.9066 22.1218 74.9936 24.354C75.185 24.7484 75.3483 25.1804 75.4772 25.6505C76.2903 28.5906 77.8746 30.5996 79.8518 31.8257C82.1242 33.2362 84.9251 33.6222 87.678 33.2056C88.2684 33.1161 88.897 33.071 89.5477 33.071C92.058 33.071 94.9299 33.7392 97.2801 35.0969C99.6653 36.4744 101.518 38.5591 101.943 41.3768C102.003 41.7767 102.034 42.1887 102.034 42.6137C102.034 43.8697 102.001 45.089 101.971 46.2013C101.85 50.7468 101.778 53.4185 104.35 54.9352C104.559 55.0583 104.751 55.1691 104.958 55.2899H104.96C108.976 57.6131 118.856 63.3312 115.33 72.5597C111.704 82.0458 110.872 83.0908 110.87 83.0947C110.743 83.2598 110.506 83.2919 110.34 83.165C110.174 83.0381 110.143 82.8003 110.27 82.6352C110.271 82.6314 111.036 81.6758 114.623 72.2906C117.92 63.663 108.436 58.1742 104.581 55.9443C104.343 55.8059 104.125 55.6798 103.969 55.5873C101.012 53.8443 101.088 51.0074 101.218 46.1837C101.246 45.1257 101.277 43.9653 101.277 42.6137C101.277 42.2254 101.249 41.8493 101.195 41.49C100.809 38.9283 99.1032 37.021 96.9048 35.752C94.6744 34.4639 91.9405 33.8302 89.5477 33.8302C88.9321 33.8302 88.3409 33.8722 87.7902 33.9555C84.8733 34.3966 81.8907 33.98 79.4559 32.4687C77.3239 31.1462 75.6183 28.9927 74.7502 25.8523C74.6343 25.4334 74.4871 25.045 74.3132 24.6865C73.3467 22.702 71.5305 21.6853 69.471 21.2962C67.3634 20.8971 65.0025 21.1501 63.017 21.7013C62.139 21.9444 61.3342 22.2471 60.6569 22.5789Z"
            fill="#774D30"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1375_1342"
          x="18.6539"
          y="12"
          width="113.692"
          height="142.307"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.34608" />
          <feGaussianBlur stdDeviation="1.17304" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1375_1342"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1375_1342"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1375_1342"
          x1="0.176758"
          y1="0.63916"
          x2="160.419"
          y2="13.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D19A08" />
          <stop offset="0.276042" stopColor="#FEDB7E" />
          <stop offset="0.5" stopColor="#EAC460" />
          <stop offset="0.661458" stopColor="#D19A08" />
          <stop offset="1" stopColor="#D19A08" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FemaleAvatar;
